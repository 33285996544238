import React, { FunctionComponent, useContext } from 'react';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { Icon } from '../common/Icon';
// Styles
import './Sidebar.sass';
import { AppLogoWhite } from '../assets';
import { AppAuthContext } from '../App';

export interface SidebarLink {
	name: string,
	path: string,
	icon: string,
}

type SidebarProps = {links: SidebarLink[]} & RouteComponentProps;

export const Sidebar: FunctionComponent<SidebarProps> = ({links, history,...props}) => {
	const authContext = useContext(AppAuthContext);

	return (
		<div className='admin-sidebar'>
			<div className='admin-sidebar-hero'>
				<NavLink to='/' className='admin-sidebar-logo__wrapper'>
					<img src={AppLogoWhite} alt="app logo" className='admin-sidebar-logo'/>
				</NavLink>
			</div>
			<div className='admin-sidebar-content'>
				{links.map(({icon, name, path} : SidebarLink, index: number) => (
					<NavLink  exact activeClassName='active' to={path} className='admin-sidebar-link' key={index}>
						<div className='admin-sidebar-link__icon'>
							<Icon>{icon}</Icon>
						</div>
						<div className='admin-sidebar-link__title'>
							{name}
						</div>
					</NavLink>
				))}
				<div className='admin-sidebar-divider'></div>
				<button
					onClick={() => {
						authContext.clear();
						history.push('/');
					}}
	        className='admin-sidebar-link'>
					<div className='admin-sidebar-link__icon'>
						<Icon>exit_to_app</Icon>
					</div>
					<div className='admin-sidebar-link__title'>
						Logout
					</div>
				</button>
			</div>

		</div>
	);
};
