export class AppStorage {
	constructor(private internal: Storage = localStorage) {
	}

	get(key: string): any {
		const data = this.internal.getItem(key);

		if (data) {
			return JSON.parse(data);
		}

		return null
	}

	set(key: string, value: any) {
		localStorage.setItem(key, JSON.stringify(value));
	}
}

export const storage = new AppStorage();
