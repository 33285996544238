import React, { FunctionComponent, useEffect } from 'react';
import './FormCheckboxField.sass';
import { FormFieldProps } from './form-field';

type FormCheckboxFieldProps = { label?: string} & FormFieldProps;
export const FormCheckboxField: FunctionComponent<FormCheckboxFieldProps | any> = ({name, label, className, ...props}) => {
	const ref = React.createRef<any>();

	useEffect(() => {
		if (ref.current.value !== props.value) {
			ref.current.checked = props.value;
		}
	}, []);

	return (
		<label htmlFor={name}>
			<input
				ref={ref}
				type="checkbox"
				name={name}
				id={name}
				className={`app-form-сheckbox ${className || ''} ${props.touched ? 'touched' : ''} ${props.errors ? 'invalid' : ''}`}
				onChange={props.onChange}
				onBlur={props.onBlur}
				value={props.value}
			/>
			<span className='app-form-checkbox__label'>{ label }</span>
		</label>
	);
};
