import React, { FunctionComponent, useEffect, useState } from 'react';

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { buildBlurEvent, buildChangeEvent, FormFieldProps } from './form-field';

const ckeditorConfig = {
	// TODO: provide uploading
	ckfinder: {
		uploadUrl: 'http://example.com',
		withCredentials: true,
		headers: {
			'X-CSRF-TOKEN': 'CSFR-Token',
			Authorization: 'Bearer <JSON Web Token>'
		}
	},
};


export const FormRichTextField: FunctionComponent<FormFieldProps|any> = ({name, value = '', className, onChange, onBlur}) => {
	const [editor, setEditor] = useState();

	const handleInit =  (editor: any) => {
		setEditor(editor);

		// editor.editing.view.change( (writer: any) => {
		// 	writer.setStyle( 'height', '300px', editor.editing.view.document.getRoot())
		// });
	};

	useEffect(() => {
		if (editor && value !== editor.getData()) {
			editor.setData(value);
		}
	});

	const handleChange = ( event: any, editor: any ) => {
		const value = editor.getData();
		onChange(buildChangeEvent(event, name, value))
	};

	const handleBlur = ( event: any, editor: any ) => {
		onBlur(buildBlurEvent(event, name));
	};

	const handleFocus =  ( event: any, editor: any ) => {
		// if (onFocus) {
		// 	onFocus();
		// }
	};

	return (
		<div className={`${className || ''}`}>
			<CKEditor
				editor={ClassicEditor}
				data={value}
				config={ckeditorConfig}
				onInit={handleInit}
				onChange={handleChange}
				onBlur={handleBlur}
				onFocus={handleFocus}
			/>
		</div>
	)
};
