import React, { FunctionComponent } from 'react';
import { useFormContext } from './Form';


type FormErrorProps = {name: string};
export const FormError: FunctionComponent<FormErrorProps> = ({name}) => {
	const formContext = useFormContext();
	const touched = formContext.touched[name];
	const errors = formContext.errors[name];

	return (
		<>
			{(touched && errors) ? (
				<div className={`app-form-error`}>{errors}</div>
			) : null}
		</>
	);
};
