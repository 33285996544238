import React, { FunctionComponent, useContext } from 'react';
import * as yup from 'yup';
import { Form, FormField, FormInput, FormLabel } from '../../common/Form';
import { FlatButton } from '../../common/FlatButton';
import './Login.sass';
import { Layout } from '../../layout/Layout';
import { AuthApi } from '../auth.api';
import { AppAuthContext } from '../../App';
import { RouteComponentProps } from 'react-router-dom';

export const Login: FunctionComponent<RouteComponentProps> = ({history,  ...props}) => {
	const authContext = useContext(AppAuthContext);

	const data = {
		email: '',
		password: ''
	};
	const schema = yup.object({
		email: yup.string()
			.required('Email is required')
			.email('Please enter valid email address'),
		password: yup.string()
			.required('Password is required')
			.min(6, 'Password should be at least 6 characters')
			.max(255, 'Password should be less or equal 255 characters')
	});

	return (
		<Layout {...{history, ...props}}>
			<div className='login'>
				<div className='login__content'>
					<Form validationSchema={schema}
					      initialValues={data}
					      onSubmit={((values: any, form: any) => {
					      	AuthApi.login(values.email, values.password)
							      .then(({token}) => {
							      	authContext.token  = token;
							      	form.setSubmitting(false);
							      	history.push('/admin');
							      })
							      .catch(e => {
								      form.setSubmitting(false);
								      form.setFieldError('email', 'Email or password is invalid');
							      });
					      })}>
						<FormField name='email' className='login-form-field'>
							<FormLabel subName='Please enter valid email address'>Email</FormLabel>
							<FormInput placeholder='Email address'/>
						</FormField>

						<FormField name='password' className='login-form-field'>
							<FormLabel subName='Please enter password'>Password</FormLabel>
							<FormInput type='password'/>
						</FormField>

						<FlatButton className='login-form-submit' type='submit'>
							Submit
						</FlatButton>
					</Form>
				</div>
			</div>
		</Layout>
	);
};
