import React, { FunctionComponent, ReactNode, useContext } from 'react';
import { useFormik, FormikContextType, FormikHelpers } from 'formik';
import './Form.sass';

// TODO: replace any with more specific type any
const FormContext = React.createContext<FormikContextType<any>|null>(null);

export const useFormContext = () => {
  const formContext = useContext(FormContext);

  if (formContext === null) {
    throw new Error('Form context is invalid');
  }

  return formContext;
}

type FormProps = {
  children: ReactNode,
  initialValues: any,
  onSubmit: (values: any, form?: FormikHelpers<any>) => void | Promise<any>,
  validationSchema?: any
};

export const Form: FunctionComponent<FormProps> = ({initialValues, onSubmit, validationSchema, children}: FormProps) => {
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: validationSchema,
  });

  return (
    <form className='app-form' onSubmit={formik.handleSubmit}>
      <FormContext.Provider value={formik}>
        {children}
      </FormContext.Provider>
    </form>
  );
};
