import React, { FunctionComponent } from 'react';
import './FormInput.sass';
import { FormFieldProps } from './form-field';

type FormInputProps = {placeholder?: string, type?: 'text|email|password', autocomplete?: string} & FormFieldProps;
export const FormInput: FunctionComponent<FormInputProps|any> = ({name, className, onChange, onBlur, value, touched, errors, placeholder, type = 'text', autocomplete = '', ...rest}) => {
	return (
		<input
			type={type}
			name={name}
			className={`app-form-input ${className || ''} ${touched ? 'touched' : ''} ${errors ? 'invalid' : ''}`}
			onChange={onChange}
			onBlur={onBlur}
			value={value}
			placeholder={placeholder || ''}
			autoComplete={autocomplete}
		/>
	);
};
