import React, { FunctionComponent } from 'react';

// Styles
import './Admin.sass'
import { Sidebar, SidebarLink } from './Sidebar';
import { Route, Switch, RouteComponentProps, Redirect } from 'react-router-dom';
import { BlogIndex } from './BlogIndex';
import { BlogForm } from './BlogForm';

export const Admin: FunctionComponent<RouteComponentProps> = (props) => {
	const links: SidebarLink[] = [
		{
			name: 'Blog',
			path: '/admin/blog',
			icon: 'list'
		}
	];

	return (
		<div className='admin-page'>
			<Sidebar links={links} {...props}/>
			<div className='admin-page-content'>
				<Switch>
					<Route path='/admin/blog/new' component={BlogForm}/>
					<Route path='/admin/blog/:id' component={BlogForm}/>
					<Route path='/admin/blog' component={BlogIndex}/>
					<Redirect from='/admin' to='/admin/blog' />
				</Switch>
			</div>
		</div>
	);
};

