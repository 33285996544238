import React, { useEffect, useState } from 'react';
import { RouteProps } from 'react-router-dom';
import './Input.sass';

type InputProps = {value?: string, type?: 'text' | 'email' | 'password', name?: string, placeholder?: string, className?: string, invalid?: boolean, onChange?: Function}
const DEFAULT_INPUT_TYPE = 'text';
const DEFAULT_PLACEHOLDER = '';
const DEFAULT_CLASSNAME = '';

export const Input = ({value = '', type, name = '', invalid = false, className, placeholder, onChange = (e: any) => {}, ...props}: RouteProps & InputProps) => {
  const [val, setVal] = useState('');

  useEffect(() => {
    setVal(value || '');
  }, [value]);

  const _onChange = (e: any) => {
    setVal(e.target.value);
    onChange(e);
  };

  return (
    <input type={type || DEFAULT_INPUT_TYPE}
           name={name}
           value={val}
           required
           placeholder={placeholder || DEFAULT_PLACEHOLDER}
           className={`app-input ${className || DEFAULT_CLASSNAME} ${invalid && 'is-invalid'}`} onChange={e => _onChange(e)}/>
  );
};

