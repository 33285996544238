import Axios from 'axios';
import { config } from '../config/config';

class Auth {
	login(email: string, password: string): Promise<{token: string}> {
		return Axios.post(`${config.apiEndpoint}/login`, {email, password})
			.then(({data}) => data);
	}

	refresh() {
		return Axios.post(`${config.apiEndpoint}/refresh`)
			.then(({data}) => data);
	}
}

export const AuthApi = new Auth();
