import React  from 'react';
import { RouteProps } from 'react-router-dom';
import './FlatButton.sass';

type BtnProps = {className?: string, onClick?: Function, type?: 'button'|'submit', primary?: boolean, success?: boolean, warning?: boolean, danger?: boolean};

export const FlatButton = ({children, primary, success, warning, danger, className = '', type = 'button', onClick = (e?: MouseEvent) => {}}: RouteProps & BtnProps) => {
  if (!children)
    throw new Error('You need to provide children node for Button component');

  return (
    <button className={`app-button app-flat-button ${className} ${primary ? 'primary' : ''} ${success ? 'success' : ''} ${warning ? 'warning' : ''}  ${danger ? 'danger' : ''}`}
            onClick={(e) => onClick(e)} type={type}>
      {children}
    </button>
  )
};
