import { AppStorage, storage as appStorage } from '../storage/storage';
import { Bootable } from '../initializer/bootable';

const AUTH_TOKEN = 'auth.token';

export class AuthContext implements Bootable {
	private _token: string = '';

	constructor(private storage: AppStorage = appStorage) {}

	get token() {
		return this._token;
	}

	get authenticated() {
		return !!this._token;
	}

	set token(value) {
		this.storage.set(AUTH_TOKEN, value);
		this._token = value;
	}

	boot() {
		const token = this.storage.get(AUTH_TOKEN);
		console.log('booting auth context');

		if (token) {
			this._token = token;
		}
	}

	clear() {
		this.token = '';
	}
}

export const authContext = new AuthContext();
