import React, { FunctionComponent, useEffect, useState } from 'react';
import { Container } from "../common/Container";


import { Button } from '../common/Button';

import TwitterLogo from './images/twitter.png';
import LinkedinLogo from './images/linkedin.png';
import FacebookLogo from './images/facebook.png';
import { Header } from '../common/Header';
import { Footer } from "../common/Footer";
import { RouteComponentProps } from "react-router-dom";
// Styles
import './BlogShow.sass'
import { Post } from '../post/post';
import { postApi } from '../post/post.api';
import { assetHelper } from '../helper/asset.helper';

export const BlogShow: FunctionComponent<RouteComponentProps> = (props) => {
  const [post, setPost] = useState<Post|null>(null);

  useEffect(() => {
    postApi.get((props.match.params as any).id).then(result => {
      setPost(result);
    });
  }, []);

  if (!post) {
    return (
      <div>Loading...</div>
    );
  }

  return (
    <div>
      <Header {...props}></Header>
      <div className='post-main'>
        <div className='post-logo' style={{backgroundImage: `url(${assetHelper.path(post.image)})`}}>
        </div>
      </div>
      <Container>
        <div className='post-content'>
          <div className='post-socials'>
            <a href='#a' className='post-social'>
              <img src={TwitterLogo} alt="social " className='post-social__logo'/>
            </a>
            <a href='#a' className='post-social'>
              <img src={LinkedinLogo} alt="social " className='post-social__logo'/>
            </a>
            <a href='#a' className='post-social'>
              <img src={FacebookLogo} alt="social " className='post-social__logo'/>
            </a>
          </div>
          <div className='post-title'>{post.title}</div>
          <div className='post-text'>
            {post.content}
          </div>
          <Button className='post-back-button' onClick={() => props.history.push('/blog')}>Back to All Articles</Button>
        </div>
      </Container>
      <Footer/>
    </div>
  );
};
