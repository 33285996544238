import { config } from '../config/config';

export class AssetHelper {
	path(path: string) {
		return path.match(/(http:\/\/|https:\/\/)/) ?
			path :
			`${config.assetEndpoint}/${path}`;
	}
}

export const assetHelper = new AssetHelper();
