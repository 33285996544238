export const DEFAULT_LIMIT = 15;
export const DEFAULT_PAGE = 1;

export interface PaginationResult<T> {
	data: T[];
	total: number,
}

export class PaginationQuery {
	constructor(public page = DEFAULT_PAGE, public  limit = DEFAULT_LIMIT) {}

	clone() {
		return new PaginationQuery(this.page, this.limit);
	}

	static with(page: number, limit: number) {
		return new PaginationQuery(page, limit);
	}
}
