import React, { FunctionComponent, useEffect, useState } from 'react';
import { Table, TableBody, Button, TableCell, TableHead, TableRow, TablePagination } from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';

import { EntryHeader } from './Entry';
import { postApi } from '../post/post.api';
import { DEFAULT_LIMIT, DEFAULT_PAGE, PaginationQuery } from '../pagination/pagination.result';
import { Post } from '../post/post';
import { dateFormatter } from '../date/date.formatter';
import { ADMIN_ROUTE, BLOG_ROUTE, BLOG_ROUTE_NEW_SEGMENT } from '../config/route.consts';
import './BlogIndex.sass'

export const BlogIndex: FunctionComponent<RouteComponentProps> = ({history}) => {
	const [query, setQuery] = useState(new PaginationQuery(DEFAULT_PAGE, DEFAULT_LIMIT));
	const [posts, setPosts] = useState<Post[]>([]);
	const [total, setTotal] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_LIMIT);

	const headerActions = [
		<Button variant="outlined" onClick={() => history.push(`/${ADMIN_ROUTE}/${BLOG_ROUTE}/${BLOG_ROUTE_NEW_SEGMENT}`)}>
			New
		</Button>
	];

	const handlePageChange = (event: any, page: number) => {
		setQuery(PaginationQuery.with(++page, rowsPerPage));
	}

	const handleChangeRowsPerPage = (event: any) => {
		setRowsPerPage(parseInt(event.target.value));
	};

	const handleDelete = (id: string) => {
		if (window.confirm('Are you sure ?')) {
			postApi.delete(id)
				.then(() => setQuery(query.clone()));
		}
	};

	useEffect(() => {
		postApi.list(query)
			.then(({data, total}) => {
				setPosts(data);
				setTotal(total);
			})
	}, [query]);

	const HEADERS = [
		'Thumbnail',
		'Title',
		'Created',
		'Edit',
		'Delete'
	];

	return (
		<div>
			<EntryHeader title='Posts' actions={headerActions}/>
			<div>
				<Table>
					<TableHead>
						<TableRow>
							{
								HEADERS.map((s, i) => (
									<TableCell key={i}>{s}</TableCell>
								))
							}
						</TableRow>
					</TableHead>
					<TableBody>
						{posts.map((post) => (
							<TableRow key={post.id}>
								<TableCell>
									<div className='blog-list__thumbnail-wrapper'>
										<img className='blog-list__thumbnail' src={post.thumbnail}/>
									</div>
								</TableCell>
								<TableCell>
									<div className='blog-list__title'>{post.title}</div>
								</TableCell>
								<TableCell>{dateFormatter.llll(post.created_at)}</TableCell>
								<TableCell>
									<Button variant="outlined" color="secondary" onClick={() => history.push(`/${ADMIN_ROUTE}/${BLOG_ROUTE}/${post.id}`)}>
										Edit
									</Button>
								</TableCell>
								<TableCell>
									<Button variant="outlined" color="secondary" onClick={() => handleDelete(post.id)}>
										Delete
									</Button>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
				<TablePagination
					rowsPerPageOptions={[15, 25, 100]}
					component="div"
					count={total}
					rowsPerPage={rowsPerPage}
					page={query.page - 1}
					onChangePage={handlePageChange}
					onChangeRowsPerPage={handleChangeRowsPerPage}
				/>
			</div>
		</div>
	);
};
