import Axios from 'axios';
import { config } from '../config/config';

export const SendgridApi = {
  startTrial({email, name, telephone}: {email: string, telephone: string, name: string}) {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('name', name);
    formData.append('telephone', telephone);

    return Axios.post(`${config.apiEndpoint}/signup-trial`, formData);
  }
}; 
