import React, { FunctionComponent } from 'react';
import './FormTextArea.sass';
import { FormFieldProps } from './form-field';

type FormTextAreaProps = {placeholder?: string,  autocomplete?: string, rows?: number} & FormFieldProps;
export const FormTextArea: FunctionComponent<FormTextAreaProps|any> = ({name, className, onChange, onBlur, value, touched, errors, placeholder, autocomplete = '', rows = 3, ...rest}) => {
	return (
		<textarea
			name={name}
			className={`app-form-textarea ${className || ''} ${touched ? 'touched' : ''} ${errors ? 'invalid' : ''}`}
			onChange={onChange}
			onBlur={onBlur}
			value={value}
			rows={rows}
			placeholder={placeholder || ''}
			autoComplete={autocomplete}
		/>
	);
};
