import React, { FunctionComponent } from 'react';

import './Layout.sass';
import { Header } from '../common/Header';
import { RouteComponentProps, RouteProps } from 'react-router-dom';
import { Footer } from '../common/Footer';

export const Layout: FunctionComponent<RouteComponentProps> = (props) => {
  return (
    <div className='app-layout'>
      <Header {...props}></Header>
      {props.children}
      <Footer></Footer>
    </div>
  )
};
