import React from 'react';

import './Footer.sass';
import AppLogo from './images/footer-logo.png';

import { Container } from './Container';
import { FacebookLogo, InstagramLogo, TwitterLogo } from '../assets';

export const Footer = () => {
  return (
    <div className='app-footer'>
      <Container>
        <div className='app-footer__content'>
          <div className='app-footer__logo-wrapper'>
            <img src={AppLogo} alt="stayhub logo" className='app-footer__logo'/>
          </div>
          <div className='app-footer-section-list'>
            <div className='app-footer-section app-footer-section--company'>
              <div className='app-footer-section__title'>
                Company
              </div>
              <div className='app-footer-section__content'>
                <a href="#a" className='app-footer-section__link'>About Us</a>
                <a href="#a" className='app-footer-section__link'>Blog</a>
                <a href="#a" className='app-footer-section__link'>Our Features</a>
                <a href="#a" className='app-footer-section__link'>Start a Free Trial</a>
              </div>
            </div>
            <div className='app-footer-section app-footer-section--contact'>
              <div className='app-footer-section__title'>
                CONTACT US
              </div>
              <div className='app-footer-section__content'>
                <a href="mailto:mail@stayhub.com" className='app-footer-section__link app-footer-section__link--email'>mail@stayhub.com</a>
              </div>
            </div>
            <div className='app-footer-section app-footer-section--social'>
              <div className='app-footer-section__title'>
                SOCIALS
              </div>
              <div className='app-footer-socials'>
                <a href='//facebook.com/stayhubio' target='_blank' rel="noopener noreferrer"  className='app-footer-socials__icon-wrapper'>
                  <img src={FacebookLogo} alt="facebook logo" className='app-footer-socials__icon'/>
                </a>
                <a href='//twitter.com/stayhubio' target='_blank' rel="noopener noreferrer"  className='app-footer-socials__icon-wrapper'>
                  <img src={TwitterLogo} alt="twitter logo" className='app-footer-socials__icon'/>
                </a>
                <a href='//instagram.com/stayhubio' target='_blank' rel="noopener noreferrer"  className='app-footer-socials__icon-wrapper' style={{opacity: 0.9}}>
                  <img src={InstagramLogo} alt="instagram logo" className='app-footer-socials__icon' style={{maxWidth: '78%'}}/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <div className='app-footer__copyrights'>
        Copyright © 2020. Stayhub.
      </div>
    </div>
  )
};
