import { Bootable } from './bootable';
import { APP_INITIALIZERS } from '../config/initializers';

export class AppInitializer {
  private registry: Bootable[] = [];

  register(service: Bootable): void {
    if (this.registry.includes(service)) {
      throw new Error('Bootable service already registered');
    }

    this.registry.push(service);
  }

  async boot(): Promise<void> {
    await Promise.all(this.registry.map(r => r.boot()));
  }
}

const initializer = new AppInitializer();
APP_INITIALIZERS.forEach(b => initializer.register(b));
export const appInitializer = initializer;
