import React, { useEffect, useState } from 'react';
import './App.css';
import { Landing } from './landing/Landing';
import { BlogIndex } from './blog/BlogIndex';
import { Swiper as SwiperBase, Navigation, Pagination, Scrollbar } from 'swiper';
import 'swiper/swiper.scss';
import { BlogShow } from './blog/BlogShow';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { MODAL_ROOT } from './common/Modal';
import { Admin } from './admin/Admin';
import { Login } from './auth/components/Login';
import { GuestRoute } from './auth/components/GuestRoute';
import { PrivateRoute } from './auth/components/PrivateRoute';
import { authContext, AuthContext } from './auth/auth.context';
import { appInitializer } from './initializer/initializer';
import { ADMIN_ROUTE, BLOG_ID_PARAM, BLOG_ROUTE, LOGIN_ROUTE } from './config/route.consts';

SwiperBase.use([Navigation, Pagination, Scrollbar]);

export const AppAuthContext = React.createContext<AuthContext>(authContext);

function App() {
  const [booting, setBooting] = useState(true);

  useEffect(() => {
    appInitializer.boot().finally(() => setBooting(false))
  }, []);

  if (booting) {
    return (
      // TODO: provide more elegant loader
      <div>Loading...</div>
    );
  }

  return (
    <>
      <AppAuthContext.Provider value={authContext}>
        <div className="App">
          <Router>
            <Switch>
              <Route path={`/${BLOG_ROUTE}/:${BLOG_ID_PARAM}`} component={BlogShow}></Route>
              <Route path={`/${BLOG_ROUTE}`} component={BlogIndex}></Route>
              <PrivateRoute path={`/${ADMIN_ROUTE}`} component={Admin}/>
              <GuestRoute path={`/${LOGIN_ROUTE}`} component={Login}/>
              <Route path={'/'} component={Landing}></Route>
            </Switch>
          </Router>
        </div>
        <div id={MODAL_ROOT}>
        </div>
      </AppAuthContext.Provider>
    </>
  );
}

export default App;
