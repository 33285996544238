import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import './FormFileInput.sass'
import { buildBlurEvent, buildChangeEvent, FormFieldProps } from './form-field';
import { Icon } from '../Icon';

export const FormFileInput: FunctionComponent<FormFieldProps|any> = ({name, className, onChange, onBlur, value}) => {
	const fileInputRef = React.createRef<HTMLInputElement>();
	const [_file, _setFile] = useState<File|undefined>();
	const [image, setImage] = useState<string>('');

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files ? event.target.files[0] : undefined;
		_setFile(file);

		if (file) {
			var reader = new FileReader();
			reader.onload = () => setImage(reader.result as string);
			reader.readAsDataURL(file);
		}

		if (onChange) {
			onChange(buildChangeEvent(event, name, file));
		}
	};

	useEffect(() => {
		if (typeof value === 'string') {
			setImage(value);
		} else  {
			_file !== value && _setFile(value);
		}
	}, [value, _file]);

	const onClick = (e: any) => {
		onBlur(buildBlurEvent(e, name));
		(fileInputRef.current as any).click();
	};

	return (
		<>
			{ (_file || image)?  (
					<div className='app-file-preview__wrapper'>
						<div className='app-file-preview'>
							<img src={image} alt="" className='app-file-preview'/>
						</div>
						<div className='app-file-preview__text'>
							{_file ? _file.name : image.split("/")[image.split("/").length - 1]}
						</div>
						<button type='button' className='app-file-preview__delete' onClick={(e) => {
							_setFile(undefined);
							setImage('');
							if (onChange) {
								onChange(buildChangeEvent(e, name, null));
							}
						}}>
							<Icon>close</Icon>
						</button>
					</div>
				) : (
				<button type='button'
				        title='Add an asset'
				        className={`app-file-btn ${className}`}
				        onClick={onClick}
				>
					Add an asset
				</button>
			)
			}
			<input ref={fileInputRef}
			       style={{display: 'none'}}
			       type="file"
			       onChange={handleChange}/>
		</>
	);
};
