import { NavLink, RouteComponentProps } from 'react-router-dom';
import React, { FunctionComponent, useEffect, useState } from 'react';

import './BlogIndex.sass';
import { Button } from '../common/Button';
import { Header } from '../common/Header';
import { Footer } from '../common/Footer';
import { Container } from "../common/Container";

import { Post } from '../post/post';
import { postApi } from '../post/post.api';
import { DEFAULT_LIMIT, DEFAULT_PAGE, PaginationQuery } from '../pagination/pagination.result';
import { dateFormatter } from '../date/date.formatter';
import { TablePagination } from '@material-ui/core';
import { assetHelper } from '../helper/asset.helper';
import { BLOG_ROUTE } from '../config/route.consts';

export const BlogIndex = (props: RouteComponentProps) => {
  const [query, setQuery] = useState(new PaginationQuery(DEFAULT_PAGE, DEFAULT_LIMIT));
  const [posts, setPosts] = useState<Post[]>([]);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_LIMIT);
  const [featured, setFeatured] = useState<Post|null>(null);

  useEffect(() => {
    postApi.featured()
      .then(post => {
        setFeatured(post);
        console.log('featured', post);
      })
  }, []);

  const handlePageChange = (event: any, page: number) => {
    const q = new PaginationQuery(page + 1, rowsPerPage);
    setQuery(q);
  }

  const handleChangeRowsPerPage = (event: any) => setRowsPerPage(parseInt(event.target.value));

  useEffect(() => {
    postApi.list(query).then(({data, total}) => {
        setPosts(data);
        setTotal(total);
      })
  }, [query, setTotal]);

  return (
    <div>
      <Header {...props}></Header>
{/* Main section */}
      <div className='blog-main__wrapper'>
        <div className='blog-main' style={{backgroundImage: `url(${featured ? assetHelper.path(featured.image) : ''})`}}>
          <div className='blog-main__gradient'></div>
          <Container>
            <div className='blog-main__content'>
              <div className='blog-main__pre-title'>TOP ARTICLE</div>
              <div className='blog-main__title'>
                { featured?.title }
              </div>
              <Button className='blog-main__continue-btn' onClick={() => props.history.push(`/blog/${featured?.id}`)}>
                Continue Reading
              </Button>
            </div>
          </Container>
        </div>
      </div>

{/* Blog Recent articles */}
      <div className='blog-recent'>
        <Container>
          <div className='blog-recent-articles'>
            <div className='blog-recent-pre-title blog-pre-title'>
              RECENT ARTICLES
            </div>
            <div className='blog-recent-list'>
              {
                posts.map((post, i) => (
                  <BlogRecent key={i} post={post}></BlogRecent>
                ))
              }
            </div>
          </div>
          <div className='blog-recent-paginator'>
            <TablePagination
              rowsPerPageOptions={[15, 25, 100]}
              component="div"
              count={total}
              rowsPerPage={rowsPerPage}
              page={query.page - 1}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        </Container>
      </div>

      <Footer></Footer>
    </div>
  );
};

type BlogRecentProps = {post: Post};
export const BlogRecent: FunctionComponent<BlogRecentProps> = ({post}) => {
  return (
    <NavLink to={`/${BLOG_ROUTE}/${post.id}`} className='blog-recent-item'>
      <div className='blog-recent-item__logo-wrapper'>
        <img src={post.thumbnail} alt="" className='blog-recent-item__logo'/>
      </div>
      <div className='blog-recent-item__content-wrapper'>
        <div className='blog-recent-item__content'>
          <div className='blog-recent-item__title'>
            {post.title}
          </div>
          <div className='blog-recent-item__text'>
            {post.preview}
          </div>
          <div className='blog-recent-item__divider'></div>
          <div className='blog-recent-item__date'>
            {dateFormatter.mmmm_mm_yyyy(post.created_at)}
          </div>
        </div>
      </div>
    </NavLink>
  );
}
