import React, { FunctionComponent, useContext } from 'react';
import { RouteProps, Route, Redirect } from 'react-router-dom';

import { AppAuthContext } from '../../App';
import { AUTHENTICATED_REDIRECT_PATH } from '../../config/config';

export const GuestRoute: FunctionComponent<RouteProps> = (props) => {
	const authContext = useContext(AppAuthContext);

	if (authContext.authenticated) {
		return <Redirect to={`${AUTHENTICATED_REDIRECT_PATH}`}></Redirect>
	}

	return <Route {...props}/>;
}
