import React, { FunctionComponent } from 'react';

import './Header.sass';

import { Button } from './Button';
import { AppLogo, AppLogoLg, MenuHamburger } from '../assets';
import { Container } from './Container';
import { Link, NavLink, RouteComponentProps } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';

export const Header: FunctionComponent<RouteComponentProps> = (props) => {
  return (
    <div className='app-header'>
      <Container>
        <div className='app-header-content'>
          <Link to='/'>
            <picture>
              <source srcSet={AppLogo} media="(max-width: 568px)"/>
              <source srcSet={AppLogoLg} media="(min-width: 568px)"/>
              <img src={AppLogo} alt="app logo"/>
            </picture>
          </Link>

          <div className='app-header-links'>
            <a href='/#about-us' className='app-header-link'>About Us</a>
            <NavLink to='/blog' activeClassName='is-active' className='app-header-link' >Blog</NavLink>
            <NavHashLink to='/#our-services' className='app-header-link'>Our Services</NavHashLink>
            <NavHashLink to='/#our-features' className='app-header-link'>Our Features</NavHashLink>
            <NavHashLink to='/#start-trial' className='app-header-link app-header-link--trial'>Start a Free Trial</NavHashLink>
          </div>
          <div className='app-header-button-container'>
            <NavHashLink to='/#start-trial'>
              <Button className='app-header-button'>Start a Free Trial</Button>
            </NavHashLink>
          </div>
          <div className='app-header-menu'>
            <img className='app-header-menu__logo' src={MenuHamburger} alt="menu hamburger"/>
          </div>
        </div>
      </Container>
    </div>
  )
};
