import React, { FunctionComponent, useState } from 'react';
import { Layout } from '../layout/Layout';
import { Container } from '../common/Container';
import { Input } from '../common/Input';
import { Button } from '../common/Button';
import IphoneLogoLg from './images/iphone-logo-lg.png';
import IphoneLogo2Lg from './images/iphone-logo2-lg.png';
import Feature1Logo from './images/feature1-logo.png';
import Feature1Icon from './images/dashboard-icon.png';
import Feature2Logo from './images/feature2-logo.png';
import Feature2Icon from './images/adjust.png';
import Feature3Logo from './images/feature3-logo.png';
import Feature3Icon from './images/support.png';
import { ArrowBottom, ArrowLeft, ArrowRight, CheckIcon, GuestyLogo } from '../assets';
import { Swiper, SwiperSlide } from 'swiper/react';
import { TrialModal } from '../trial/TrialModal';
import { SendgridApi } from '../sendgrid/sendgrid.api';
import './Landing.sass';
import { RouteComponentProps } from 'react-router-dom';


export const Landing: FunctionComponent<RouteComponentProps> = (props) => {
  const [email, setEmail] = useState('');
  const [showTrialModal1, setShowTrialModal1] = useState(false);
  const [showTrialModal2, setShowTrialModal2] = useState(false);

  const onSubmit = (e: any) => {
    e.preventDefault();
    SendgridApi.startTrial({email , telephone: '', name: ''})
      .then(_ => {
        setEmail('');
        setShowTrialModal1(true);
      })
      .catch(e => {
        alert(e ? e.message : 'Failed to process request');
        setEmail('');
      });
  }

  const points = [
    'Online Check-in',
    'Branded Guest App',
    'Upsells',
    'Communication Hub',
    'Online Check-in',
    'And More...',
  ].map((point, i) => (
    <div className='l-main__point' key={i}>
      <div className='l-main__point-icon-wrapper'>
        <img src={CheckIcon} className='l-main__point-icon' alt="check icon"/>
      </div>
      <div className='l-main__point-text'>{point}</div>
    </div>
  ));

  const services = [
    {
      logo: '/images/welcome-guide.png',
      logoWeb: '/images/welcome-guide-web.png',
      text: 'After a guest places a booking with you, they receive a link to your custom guest portal for them to sign up.'
    },
    {
      logo: '/images/house-rules.png',
      logoWeb: '/images/house-rules-web.png',
      text: 'Guests can review their bookings, accept house rules and order additional services for their stay.'
    },
    {

      logo: '/images/welcome-guide2.png',
      logoWeb: '/images/welcome-guide2-web.png',
      text: 'Before and during their stay, guests can access a custom welcome guide for the unit they are staying in.'
    },
    {
      logo: '/images/cloud-services.png',
      logoWeb: '/images/cloud-services-web.png',
      text: 'You can set up customized remarketing campaigns for your past guests and incentivize repeat bookings.'
    },
  ]
    .map(s => ({...s, logo: process.env.PUBLIC_URL + s.logo, logoWeb: process.env.PUBLIC_URL + s.logoWeb}))
    .map((s, i) => (
      <div className={`l-service l-service-${i + 1}`} key={i}>
        <div className='l-service__logo-wrapper'>
          <picture>
            <source srcSet={s.logo} media="(max-width: 568px)"/>
            <source srcSet={s.logoWeb} media="(min-width: 568px)"/>
            <img src={s.logo} alt="service logo" className="l-service__logo"/>
          </picture>
        </div>
        <div className='l-service__text'>
          {s.text}
        </div>
      </div>
    ));

  const serviceGroups = [services.slice(0, 2), services.slice(2, 4)].map(([s1, s2], i) => (
    <div className='l-services-group' key={i}>
      {s1}
      {s2}
    </div>
    )
  );

  const slides = [
    [Feature1Logo, Feature1Icon, 'A dashboard for your guests to view their upcoming and past bookings'],
    [Feature2Logo, Feature2Icon, 'Create custom membership and referral programs for your guests'],
    [Feature3Logo, Feature3Icon, 'Upsell services to your guests, like parking and early check-in']
  ].map(([logo, icon, text], i) => (
    <SwiperSlide key={i}>
      <div className='l-feature'>
        <div className='l-feature__logo-wrapper'>
          <img src={logo} alt="feature logo" className='l-feature__logo'/>
        </div>
        <div className='l-feature__note'>
          <div className='l-feature__icon-wrapper'>
            <img src={icon} alt="feature logo" className='l-feature__icon'/>
          </div>
          <div className='l-feature__text'>
            { text }
          </div>
        </div>
      </div>
    </SwiperSlide>
  ));

  return (
    <Layout {...props}>
      <div className=''>
{/* Main section */}
        <div className='l-main'>
          <Container>
            <div className='l-main__content'>
              <div className='l-main__logos'>
                <div className='l-main__video'>
                  <video autoPlay={true} loop={true} muted>
                    <source src={process.env.PUBLIC_URL + '/videos/main-video-2.mp4'} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
                    <img src={IphoneLogoLg} alt='phone' className='l-main__logo'/>
                  </video>
                </div>

                <div className='l-main__video l-main__video--second'>
                  <video autoPlay={true} loop={true} muted>
                    <source src={process.env.PUBLIC_URL + '/videos/main-video.mp4'} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
                    <img src={IphoneLogo2Lg} alt='phone' className='l-main__logo'/>
                  </video>
                </div>
              </div>
              <div className='l-main__description'>
                <div className='l-main__title'>Convert guests into repeat guests</div>
                <div className='l-main__points'>
                  { points }
                </div>
                <form className='l-main-form' onSubmit={onSubmit}>
                  <Input value={email} onChange={(e: any) => setEmail(e.target.value)} type='email' placeholder='Enter Your Email' className='l-main-form__input' />
                  <Button className='l-main-form__button' type='submit'>Try For Free</Button>
                </form>
              </div>
            </div>
          </Container>
          <TrialModal isCompleted={true} isOpen={showTrialModal1} closeRequested={() => setShowTrialModal1(false)}/>
        </div>

{/* About section */}
        <div className='l-about' id='about-us'>
          <Container>
            <div className='l-about-content'>
              <h3 className='l-about__pre-title l-pre-title'>ABOUT US</h3>
              <h2 className='l-about__title l-title'>Reduce your reliance on booking platforms and increase your bottom line</h2>
              <div className='l-about__text l-text'>
                As a host on booking platforms like Airbnb & VRBO, you may be using a platform management system to manage bookings, but you're leaving the ownership of the guests to the booking platform. Stayhub allows you to take back the ownership of your guests, improve their experience and convert them into repeat customers.
              </div>
            </div>
          </Container>
        </div>
{/*  TODO: add divider like on design */}
        <div className='l-services-divider'></div>
{/* Services section */}
        <div className='l-services' id='our-services'>
          <Container>
            <div className='l-services__content'>
              <h3 className='l-services__pre-title l-pre-title'>OUR SERVICES</h3>
              <h2 className='l-services__title l-title'>Here’s how it works in a nutshell</h2>
              <div className='l-services__text'>
                &nbsp;
              </div>

              <div className='l-services__list'>
                { serviceGroups }
              </div>
            </div>
          </Container>
        </div>
      </div>
{/* Guesty section */}
      <div className='l-guesty'>
        <Container>
          <div className='l-guesty__content'>
            <div className='l-guesty__logo-wrapper'>
              <img src={GuestyLogo} alt="guesty logo"/>
            </div>
            <h2 className='l-guesty__title l-title'>
              You can use Stayhub as a standalone service for your business, or integrate with your PMS provider
            </h2>
          </div>
        </Container>
      </div>

{/* Features section */}
      <div className='l-features' id='our-features'>
        <Container>
          <div className='l-features__content'>
            <h3 className='l-pre-title'>OUR SERVICES</h3>
            <h2 className='l-features__title l-title'>Stayhub Features</h2>
          </div>
        </Container>
        <div className='l-features__list'>

          <Swiper
            spaceBetween={20}
            slidesPerView={1}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
            navigation={{nextEl: '#nextFeatureArrow', prevEl: '#prevFeatureArrow'}}
            pagination={{el: '.swiper-pagination', type: 'bullets',}}
            breakpoints={{
              768: {
                slidesPerView: 2,
              },
              1250: {
                slidesPerView: 3,
              }
            }}
          >
            { slides }
          </Swiper>

          <div className='l-features__list-arrows'>
            <div id='prevFeatureArrow' className='l-features__list-arrow'>
              <img src={ArrowLeft} alt="arrow left"/>
            </div>
            <div id='nextFeatureArrow' className='l-features__list-arrow'>
              <img src={ArrowRight} alt="arrow left"/>
            </div>
          </div>

        </div>

      </div>
{/*  *** Starting section */}
      <div className='l-starting-wrapper' id='start-trial'>
        <div className='l-starting'>
          <div className='l-starting__title'>
            Starting is quick and easy!
          </div>
          <div className='l-starting__text'>
            Join other smart hosts around the world that are increasing their bottom line
          </div>
          <div className='l-starting__arrow-wrapper'>
            <img src={ArrowBottom} alt="arrow bottom" className='l-starting__arrow'/>
          </div>
          <Button className='l-starting__button' onClick={() => { setShowTrialModal2(true);}}>Start a free trial</Button>
          {/* TODO: uncomment or remove */}
          {/*<a href="#a" className='l-starting__demo-link'>Book a Demo</a>*/}
        </div>
      </div>
      <div className='l-bottom-spacer'></div>
      <TrialModal isOpen={showTrialModal2} closeRequested={() => setShowTrialModal2(false)}></TrialModal>
    </Layout>
  )
};
