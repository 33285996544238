import React, { FunctionComponent, useEffect, useState } from 'react';
import * as yup from 'yup';

import { FormFileInput, Form, FormError, FormField, FormInput, FormLabel, FormRichTextField } from '../common/Form';
import { EntryHeader } from './Entry';
import './BlogForm.sass';
import { FormCheckboxField } from '../common/Form/FormCheckboxField';
import { postApi } from '../post/post.api';
import { RouteComponentProps } from 'react-router-dom';
import { PostData } from '../post/post.data';
import { Button } from '@material-ui/core';
import { ADMIN_ROUTE, BLOG_ROUTE } from '../config/route.consts';
import { FormTextArea } from '../common/Form/FormTextArea';

export const BlogForm: FunctionComponent<RouteComponentProps> = ({history, match: {params}}, ...props) => {
	const [fetching, setFetching] = useState(true);
	const [initialValues, setInitialValues] = useState<PostData>({
		title: '',
		preview: '',
		content: '',
		image: undefined,
		thumbnail: undefined,
		featured: false,
	});

	const schema = yup.object({
		title: yup.string()
			.required('Blog title is required')
			.min(5, 'Title length should be at least 5')
			.max(255, 'Title length should not exceed 255 characters'),
		preview: yup.string()
			.required('Blog preview is required')
			.min(5, 'Preview length should be at least 5')
			.max(500, 'Preview length should not exceed 500 characters'),
		image: yup.mixed()
			.required("Blog image is required")
			.test(
				"image",
				"File too large",
				value => true
			),
		thumbnail: yup.mixed().required("Thumbnail is required"),
		content: yup.string().required('Content is required').min(5, 'Content length should be at least 5'),
	});

	const id = (params as any).id;

	useEffect(() => {
		if (id) {
			postApi.get(id).then(res => {
				setInitialValues({
					title: res.title,
					content: res.content,
					image: res.image,
					thumbnail: res.thumbnail,
					featured: res.featured,
					preview: res.preview
				});
				setFetching(false);
			}).catch(() => {
				setFetching(false);
			})
		} else {
			setFetching(false);
		}
	}, [id]);

	if (id && fetching) {
		return <div></div>
	}

	return (
		<div className='blog-form-new'>
			<EntryHeader title={id ? 'Edit post' : 'Create new post'} actions={[]}/>
			<div className='blog-form'>
				<Form initialValues={initialValues}
							validationSchema={schema}
							onSubmit={(values: any, form) => {
								const promise = id ? postApi.update(id, values) : postApi.create(values);
								promise
									.then(() => {
										history.push(`/${ADMIN_ROUTE}/${BLOG_ROUTE}`)
									})
									.catch(e => alert(e.message))
									.finally(() => {
										if (form) {
											form.resetForm({values: null});
										}
									});
							}}
				>

					<FormField name='title' className='blog-form__field'>
						<FormLabel required>Title</FormLabel>
						<FormInput className='blog-form__input'/>
					</FormField>

					<FormField name='preview' className='blog-form__field'>
						<FormLabel required>Preview</FormLabel>
						<FormTextArea className='blog-form__input'/>
					</FormField>


					<FormField name='featured' className='blog-form__field'>
						<FormLabel>Is Featured</FormLabel>
						<FormCheckboxField label='Is featured'/>
						<FormError name='featured'/>
					</FormField>

					<FormField name='image'  className='blog-form__field'>
						<FormLabel required subName='We recommend a minimum width of 1440px and a minimum height of 522px.'>Hero image</FormLabel>
						<FormFileInput className=''/>
					</FormField>

					<FormField name='thumbnail'  className='blog-form__field'>
						<FormLabel required subName='We recommend a squared image.'>Thumbnail image</FormLabel>
						<FormFileInput className=''/>
					</FormField>

					<FormField name='content' className='blog-form__field'>
						<FormLabel required>Blog content</FormLabel>
						<FormRichTextField/>
					</FormField>

					<div className='blog-form__actions'>
						<Button variant="outlined" className='blog-form__action' onClick={() => history.push(`/${ADMIN_ROUTE}/${BLOG_ROUTE}`)}>
							Back
						</Button>
						<Button variant="outlined" color="secondary" className='blog-form__action' type='submit'>
							Save
						</Button>
					</div>
				</Form>
			</div>
		</div>
	);
};
