import React, { FunctionComponent, ReactElement } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './FormDateField.sass'
import { buildBlurEvent, buildChangeEvent, FormFieldProps } from './form-field';

export const FormDateField: FunctionComponent<FormFieldProps|any> = ({name, className, value, onChange, onBlur}) => {
	console.log('classname', className);
	return (
		<DatePicker className={`app-form-datefield ${className || ''}`}
		            selected={value}
		            onBlur={event => {
			            onBlur(buildBlurEvent(event, name));
		            }}
		            onChange={(date: any) => {
		            	onChange(buildChangeEvent({}, name, date));
		            }}
		/>
	);
}

export const FormFieldBase: FunctionComponent<any> = (element: ReactElement) => {
	return <>
		{React.cloneElement(element, {a: 1, b: 2})}
	</>
}
