import React, { useEffect, useState } from 'react';
import { Input } from '../common/Input';
import { Button } from '../common/Button';
import { Modal } from '../common/Modal';
import './TrialModal.sass';

import CompletedLogo from './images/completed-logo.png';
import { SendgridApi } from '../sendgrid/sendgrid.api';

export const TrialModal = ({isOpen = false, isCompleted = false, closeRequested}: {isOpen?: boolean, isCompleted?: boolean, closeRequested: Function}) => {
  const [showModal, setShowModal] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [telephone, setTelephone] = useState('');
  const [emailError, setEmailError] = useState('');
  const [nameError, setNameError] = useState('');
  const setTelephoneError = useState('')[1];

  const reset = () => {
    setProcessing(false);
    setCompleted(false);
    setEmail('');
    setEmail('');
    setTelephone('');
    setName('');
    setEmailError('');
    setNameError('');
    setTelephoneError('');
  };

  function validateEmail(email: string) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const startNow = () => {
    if (!validateEmail(email)) {
      setEmailError('Email is invalid');
      return;
    }
    setProcessing(true);

    SendgridApi.startTrial({email , telephone, name})
      .then(_ => {
        setCompleted(true);
        setProcessing(false);
      })
      .catch(_ => {
        setEmailError('Failed to process data');
        setProcessing(false);
      });
  }

  const onChangeInput = (e: any) => {
    const value = e.target.value;
    switch (e.target.name) {
      case 'name':
        setName(value);
        setNameError('');
        break;
      case 'telephone':
        setTelephone(value);
        setTelephoneError('');
        break;
      case 'email':
        setEmail(value);
        setEmailError('');
        break;
    }
  };

  useEffect(() => setCompleted(isCompleted), [isCompleted]);
  useEffect(() => setShowModal(isOpen), [isOpen]);

  return (
    <Modal isOpen={showModal} closeRequested={() => {
      reset();
      closeRequested();
    }}>
      <div className='trial-modal'>
        <div className='trial-modal-sidebar'>
          <div className='trial-modal-sidebar__line1'>100% Free</div>
          <div className='trial-modal-sidebar__line2'>Free Trial</div>
          <div className='trial-modal-sidebar__line3'>Start Now</div>
        </div>
          { completed ?  (
            <div className='trial-modal-completed'>
              <img src={CompletedLogo} alt="completed logo" className='trial-modal-completed__logo'/>
              <div className='trial-modal-completed__title'>
                You're all set.
              </div>
              <div className='trial-modal-completed__text'>
                Check your email!
              </div>
            </div>
          ) : (
            <div className='trial-modal-content'>
              <div className='trial-modal-form'>
                <div className={`trial-modal-form__group ${nameError && 'is-invalid'}`}>
                  <Input className='trial-modal-form__input' name='name' placeholder='Name' invalid={!!nameError} onChange={onChangeInput}></Input>
                  <div className='form-error'>{ nameError }</div>
                </div>

                <div className={`trial-modal-form__group `}>
                  <Input className='trial-modal-form__input' name='telephone'  placeholder='Phone Number'  onChange={onChangeInput}></Input>
                </div>

                <div className={`trial-modal-form__group ${emailError && 'is-invalid'}`}>
                  <Input className='trial-modal-form__input' name='email' placeholder='Email' type='email' invalid={!!emailError}  onChange={onChangeInput}></Input>
                  <div className='form-error'>{ emailError }</div>
                </div>

                <div className='trial-modal-actions'>
                  <Button className='trial-modal-form__back' onClick={() => {setShowModal(false)}}>Back</Button>
                  <Button className='trial-modal-form__submit' onClick={startNow}>{processing ? 'Processing...' : 'Start Now'}</Button>
                </div>
              </div>
            </div>
          )
        }
      </div>
    </Modal>
  );
}
