import React, { FunctionComponent } from 'react';
import './FormLabel.sass';

type FormLabelProps = {subName?: string, required?: boolean};
export const FormLabel: FunctionComponent<FormLabelProps> = ({children, subName, required}) => {
	return (
		<div className='app-form-label-wrapper'>
			<label className='app-form-label'>
				{children}
				{required ? (
					<span className='app-form-label__required'>*</span>
				) : ''}
			</label>
			{subName ? (
				<div className='app-form-sublabel'>
					{subName}
				</div>
			) : null}
		</div>
	);
};
