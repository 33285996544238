import React, { FunctionComponent, ReactNode } from 'react';

// Styles
import './Entry.sass';

type EntryHeaderProps = {title: string, actions: ReactNode[]};

export const EntryHeader: FunctionComponent<EntryHeaderProps> = ({title, actions}) => {
	return (
		<div className='entry-header'>
			<div className='entry-header-title'>
				{title}
			</div>
			<div className='entry-header-actions'>
				{actions.map(a => a)}
			</div>
		</div>
	);
}
