import React, { FunctionComponent, ReactElement } from 'react';
import { useFormContext } from './Form';
import { FormError } from './FormError';
// import { FormInput2 } from './FormInput';
import {  FormikContextType, } from 'formik';
import { FormInput } from './FormInput';
import { FormFileInput } from './FormFileInput';
import { FormCheckboxField } from './FormCheckboxField';
import { FormDateField } from './FormDateField';
import { FormRichTextField } from './FormRichTextField';
import { FormTextArea } from './FormTextArea';

const FORM_FIELD_KNOWN_TYPES: string[] = [
	FormInput,
	FormFileInput,
	FormCheckboxField,
	FormDateField,
	FormRichTextField,
	FormTextArea,
].map(t => t.name);

const shouldPassProps = (e: ReactElement) => FORM_FIELD_KNOWN_TYPES.includes((e.type as any).name);
const childrenWithProps = (formContext: FormikContextType<any> , element: ReactElement, name: string, index: any) => {
	return React.cloneElement(element as ReactElement, {
		key: index,
		name: name,
		value: formContext.values[name],
		...formContext.getFieldProps(name),
		touched: formContext.touched[name],
		errors: formContext.errors[name],
	});
}

type FormFieldProps = {name: string, displayError?: boolean, className?: string};
export const FormField: FunctionComponent<FormFieldProps> = ({name, displayError = true, children, className}) => {
	const formContext = useFormContext();

	const elements: ReactElement[] = (children instanceof Array ? children : [children])
		.map((e: any, index) => shouldPassProps(e) ? childrenWithProps(formContext, e, name, index) : e);

	return (
		<div className={`app-form-field ${className}`}>
			{elements}
			{displayError ? <FormError name={name}/> : null}
		</div>
	);
};
