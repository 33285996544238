import axios from 'axios';
import { config } from '../config/config';
import { Post } from './post';
import { PaginationQuery, PaginationResult } from '../pagination/pagination.result';
import { type } from 'os';
import { assetHelper } from '../helper/asset.helper';

export class PostApi  {
  list(query: PaginationQuery): Promise<PaginationResult<Post>> {
    return axios.get(`${config.apiEndpoint}/posts?page=${query.page}&limit=${query.limit}`)
      .then(({data}) => data)
      .then(response => {
        return {
          ...response,
          data: response.data.map((post: Post) => ({
            ...post,
            thumbnail: assetHelper.path(post.thumbnail),
            image: assetHelper.path(post.image)
          }))
        };
      });
  }

  featured(): Promise<Post> {
    return axios.get(`${config.apiEndpoint}/posts/featured`)
      .then(({data}) => data)
      .then((post: Post) => ({
        ...post,
        thumbnail: assetHelper.path(post.thumbnail),
        image: assetHelper.path(post.image)
      }));
  }

  get(id: string): Promise<Post> {
    return axios.get(`${config.apiEndpoint}/posts/${id}`)
      .then(({data}) => data)
      .then((post: Post) => ({
        ...post,
        thumbnail: assetHelper.path(post.thumbnail),
        image: assetHelper.path(post.image)
      }));
  }

  update(id: string, data: {title: string, content: string, preview: string, image: File, thumbnail: File, featured: boolean}) {
    const formData = new FormData();
    formData.append('title', data.title);
    formData.append('content', data.content);
    formData.append('preview', data.preview);

    if (data.image && typeof data.image === 'object') {
      formData.append('image', data.image);
    }

    if (data.thumbnail && typeof data.thumbnail === 'object') {
      formData.append('thumbnail', data.thumbnail);
    }

    if (data.featured) {
      formData.append('featured', 'true');
    }
    formData.append('_method', 'PATCH');

    return axios.post(`${config.apiEndpoint}/posts/${id}`, formData)
      .then(({data}) => data);
  }

  create(data: {title: string, content: string, preview: string, image: File, thumbnail: File, featured: boolean}) {
    const formData = new FormData();
    formData.append('title', data.title);
    formData.append('content', data.content);
    formData.append('preview', data.preview);
    formData.append('image', data.image);
    formData.append('thumbnail', data.thumbnail);

    if (data.featured) {
      formData.append('featured', 'true');
    }

    return axios.post(`${config.apiEndpoint}/posts`, formData)
      .then(({data}) => data);
  }

  delete(id: string) {
    return axios.post(`${config.apiEndpoint}/posts/${id}`, {_method: 'DELETE'})
      .then(({data}) => data);
  }
}

export const postApi = new PostApi();
