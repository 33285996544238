import React, { FunctionComponent, useContext } from 'react';
import { AppAuthContext } from '../../App';
import { RouteProps, Route, Redirect } from 'react-router-dom';
import { NOT_AUTHENTICATED_REDIRECT_PATH } from '../../config/config';

export const PrivateRoute: FunctionComponent<RouteProps> = (props) => {
	const authContext = useContext(AppAuthContext);

	if (!authContext.authenticated) {
		return <Redirect to={`${NOT_AUTHENTICATED_REDIRECT_PATH}`}/>
	}

	return <Route {...props}/>;
}
