import moment from 'moment';

export const MMMM_MM_YYYY = 'MMMM MM, YYYY';
export const YYYY_MM_DD = 'YYYY-MM-DD';
export const LLLL = 'llll';

export class DateFormatter {
	format(date: Date|string, format: string) {
		if (typeof date === 'string') {
			date = new Date(date);
		}

		return moment(date).format(format);
	}

	yyyy_mm_dd(date: Date|string) {
		return this.format(date, YYYY_MM_DD);
	}

	mmmm_mm_yyyy(date: Date|string) {
		return this.format(date, MMMM_MM_YYYY);
	}

	llll(date: Date|string) {
		return this.format(date, LLLL);
	}
}

export const dateFormatter = new DateFormatter();
