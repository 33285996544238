import axios from 'axios';
import { authContext } from '../auth/auth.context';

axios.interceptors.request.use((config) => {
	if (authContext.authenticated) {
		config.headers['Authorization'] = `bearer ${authContext.token}`;
	}

	return config;
});

axios.interceptors.response.use((config) => {
		if (config.headers['Authorization'] )	{
			authContext.token = config.headers['Authorization'];
		}

		return config;
	},
	(error) => {
		if (!error || !error.response) {
			return Promise.reject(error);
		}

		const {response: {status}, config} = error;



		if (status === 401) {
			if (authContext.authenticated) {
				// authContext.clear();
				if (!config.__retry) {
					config.__retry = true;
					axios.request(config);
				}
			}
		}

		return Promise.reject(error);
	});
